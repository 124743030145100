<script setup lang="ts">
const { page } = useContent()
</script>

<template>
    <AppContainer padded class="relative flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-8">
        <div class="lg:col-span-8 lg:col-start-3">
            <slot />
        </div>
    </AppContainer>
</template>
